import { EngineSDK } from '@dilvant/engine-sdk';
import { hideButtons } from '..';

//***  OPEN MODAL CEREMONIAL LOCATION   ***//

const ceremonyModal = document.getElementById('modalCeremony');
ceremonyModal.style.display = 'none';

const openCeremonyModal = document.getElementById('openCeremony');
openCeremonyModal.addEventListener('click', () => {
	ceremonyModal.style.display = 'flex';
});

const closeCeremonialsModalBtn = document.getElementById(
	'closeCeremonialsModalBtn'
);
closeCeremonialsModalBtn.addEventListener('click', () => {
	ceremonyModal.style.display = 'none';
});

const openCeremonialMap = document.getElementById('redirectMapCeremonial');
openCeremonialMap.addEventListener('click', () => {
	window.open(
		'https://www.google.com/maps/place/Bas%C3%ADlica+Mar%C3%ADa+Auxiliadora/@-12.0612034,-77.0451853,17z/data=!3m1!4b1!4m6!3m5!1s0x9105c8c2e2890d21:0x39a097d3a3566caa!8m2!3d-12.0612087!4d-77.0426104!16s%2Fg%2F11bwqmt85_?entry=ttu',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === ceremonyModal) {
		ceremonyModal.style.display = 'none';
	}
});

//***  OPEN MODAL CELEBRATION LOCATION   ***//

const celebrationModal = document.getElementById('modalCelebration');
celebrationModal.style.display = 'none';

const openCelebrationModal = document.getElementById('openCelebration');
openCelebrationModal.addEventListener('click', () => {
	celebrationModal.style.display = 'flex';
});

const closeModalCelebration = document.getElementById('closeModalCelebration');
closeModalCelebration.addEventListener('click', () => {
	celebrationModal.style.display = 'none';
});

const openCelebrationMap = document.getElementById('redirectMapCelebration');
openCelebrationMap.addEventListener('click', () => {
	window.open(
		'https://www.google.com/maps/d/edit?mid=1uvdMOvs0eTLfiDmMlM2D7gWzFbXjWh4',
		'_blank'
	);
});

window.addEventListener('click', (event) => {
	if (event.target === celebrationModal) {
		celebrationModal.style.display = 'none';
	}
});

//***  OPEN MODAL TABLE GIFT   ***//

const modalTableGift = document.getElementById('modalTableGift');
modalTableGift.style.display = 'none';

const btnOpenModalGift = document.getElementById('btnOpenModalGift');
btnOpenModalGift.addEventListener('click', () => {
	modalTableGift.style.display = 'flex';
});

const closeModalTableGift = document.getElementById('closeModalTableGift');
closeModalTableGift.addEventListener('click', () => {
	modalTableGift.style.display = 'none';
});

// const btnFalabella = document.getElementById('btnFalabella');
// btnFalabella.addEventListener('click', () => {
// 	window.open('https://www.falabella.com.pe/falabella-pe', '_blank');
// });

window.addEventListener('click', (event) => {
	if (event.target === modalTableGift) {
		modalTableGift.style.display = 'none';
	}
});

const copyAccount = document.getElementById('copyAccount');
copyAccount.addEventListener('click', () => {
	const account = '8983216547324';
	navigator.clipboard
		.writeText(account)
		.then(function () {
			// console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});

const copyCCI = document.getElementById('copyCCI');
copyCCI.addEventListener('click', () => {
	const CCI = 'CCI: 00389801321654732441';
	navigator.clipboard
		.writeText(CCI)
		.then(function () {
			// console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});

const copyAccountBCP = document.getElementById('copyAccountBCP');
copyAccountBCP.addEventListener('click', () => {
	const account = '191-94160439-0-53';
	navigator.clipboard
		.writeText(account)
		.then(function () {
			// console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});

const copyCCIbcp = document.getElementById('copyCCIbcp');
copyCCIbcp.addEventListener('click', () => {
	const CCI = 'CCI: 00219119416043905358';
	navigator.clipboard
		.writeText(CCI)
		.then(function () {
			// console.log('Texto copiado al portapapeles');
		})
		.catch(function (err) {
			console.error('Error al copiar texto: ', err);
		});
});

// CONFIRM ASSISTANCE

const btnConfirmAsistance = document.getElementById('btnConfirmAsistance');
const btnDeclineAsistance = document.getElementById('btnDeclineAsistance');

btnConfirmAsistance.addEventListener('click', () => {
	let phoneNumber = '+51991893281';
	let encodedMessage = encodeURIComponent(
		'Es un honor para mí ser parte de este momento tan importante en sus vidas. Soy ___________ y confirmo mi asistencia a la boda. Les envío un fuerte abrazo. 🤗👏'
	);

	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

	window.open(url, '_blank');
	document.querySelector("#confirmName").innerHTML = `¡Muchas gracias por haber confirmado tu invitación a nuestra boda, esperamos que disfrutes de este hermoso momento tanto como nosotros!`;
	hideButtons();
});

btnDeclineAsistance.addEventListener('click', () => {
	let phoneNumber = '+51991893281';
	let encodedMessage = encodeURIComponent(
		'Lamento mucho no poder asistir a la boda. Soy ___________ y les agradezco de corazón la invitación. Tienen mi apoyo y mi cariño en este día tan importante. Espero que disfruten de la celebración y que sean muy felices. 😢🙏'
	);

	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

	window.open(url, '_blank');

	document.querySelector("#confirmName").innerHTML = `Lamentamos que no puedas asistir. Si cambias de opinión, por favor contáctanos. ¡Deseamos encontrarnos en otra oportunidad!`;
	hideButtons();
});

// document.addEventListener('DOMContentLoaded', function () {
// 	const form = document.getElementById('confirm-form');
// 	const nameInput = document.getElementById('nameConfirm');
// 	const emailInput = document.getElementById('emailConfirm');
// 	const submitBtn = document.getElementById('sendConfirmModal');

// 	form.addEventListener('input', function () {
// 		const isNameValid = nameInput.value.trim() !== '';
// 		const isEmailValid = emailInput.value.trim() !== '';

// 		submitBtn.disabled = !(isNameValid && isEmailValid);
// 	});

// 	form.addEventListener('submit', function (event) {
// 		event.preventDefault();


// 		let email = {
// 			subject: 'Confirmación de asistencia',
// 			sender: {
// 				email: 'hello@dilvant.com',
// 				name: 'Dilvant'
// 			},
// 			receivers: [
// 				{ email: 'cristian198519@hotmail.com', name: 'Cristian' }
// 			],
// 			content: {
// 				type: 'text/html',
// 				// value: getTemplateCardInvitation(i.fullname, `${this.webcardUrl}?event=${i.event}&guest=${i.id}`)
// 				value: getTemplateConfirmOrDecline('confim', nameInput.value)
// 			}
// 		};

// 		EngineSDK.Email.send(email);

// 		document.querySelector("#confirmName").innerHTML = `¡Muchas gracias por haber confirmado tu invitación a nuestra boda, esperamos que disfrutes de este hermoso momento tanto como nosotros!`;

// 		hideButtons();

// 	});
// });

// document.addEventListener('DOMContentLoaded', function () {
// 	const form = document.getElementById('decline-form');
// 	const nameInput = document.getElementById('nameDecline');
// 	const emailInput = document.getElementById('emailDecline');
// 	const submitBtn = document.getElementById('sendDeclineModal');

// 	form.addEventListener('input', function () {
// 		const isNameValid = nameInput.value.trim() !== '';
// 		const isEmailValid = emailInput.value.trim() !== '';

// 		submitBtn.disabled = !(isNameValid && isEmailValid);

// 	});

// 	form.addEventListener('submit', function (event) {
// 		event.preventDefault();



// 		let email = {
// 			subject: 'Rechazo de asistencia',
// 			sender: {
// 				email: 'hello@dilvant.com',
// 				name: 'Dilvant'
// 			},
// 			receivers: [
// 				{ email: 'lizie_rock18@hotmail.com', name: 'Cristian' }
// 			],
// 			content: {
// 				type: 'text/html',
// 				// value: getTemplateCardInvitation(i.fullname, `${this.webcardUrl}?event=${i.event}&guest=${i.id}`)
// 				value: getTemplateConfirmOrDecline('decline', nameInput.value)
// 			}
// 		};

// 		EngineSDK.Email.send(email);

// 		document.querySelector("#confirmName").innerHTML = `Lamentamos que no puedas asistir. Si cambias de opinión, por favor contáctanos. ¡Deseamos encontrarnos en otra oportunidad!`;

// 		hideButtons();
// 	});
// });

// /// template confirm assistance or decline

// function getTemplateConfirmOrDecline(type, guest) {
// 	let title = '';
// 	let text1 = '';
// 	let text2 = '';

// 	if (type == 'confim') {
// 		title = '¡Confirmación de asistencia!';
// 		text1 = `Yo ${guest} confirmo mi asistencia a la boda. ¡Estoy emocionado/a y honrado/a de haber recibido su invitación para celebrar este día tan especial! 😊✨ No puedo esperar para ser parte de su amor y alegría en su boda.`;
// 		text2 =
// 			'Estoy ansioso/a de compartir este día mágico con ustedes. ¡Gracias por invitarme a ser parte de su cuento de hadas! 👰🤵‍♂️💐';
// 	} else {
// 		(title = '¡No podré asistir!'),
// 			(text1 = `Yo ${guest} no podré ir a la boda. Espero que este mensaje los encuentre rodeados de amor y emoción mientras planifican su día especial. Lamento profundamente informarles que no podré asistir a su boda.`);
// 		text2 =
// 			'Les deseo un día lleno de amor, risas y momentos inolvidables. ¡Que su matrimonio esté lleno de alegría y felicidad perpetua! 💕🎉';
// 	}

// 	return `<!DOCTYPE html><html><head><meta charset="UTF-8"><title>Tu Título Aquí</title><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,400;1,700&family=Inter:wght@300;400;600&display=swap" rel="stylesheet"><style>#container{background-color:white;margin:0 auto; min-height: 800px;width:448px;border-radius:20px;}#container>tbody{box-shadow:0px 0px 7px 1px #bfa3367a;outline:1px solid #f2d46ecc;border-radius:20px;background-image:url(https://objects-us-east-1.dream.io/augurdigital/dilvant/bg-card.png);background-repeat:no-repeat;background-size:cover;}p{color:#998379;font-size:14px;font-family:'Averia Serif Libre',sans-serif;font-weight:400;line-height:150%;font-style:normal;text-align:left;}tbody{width:216px;padding:0 122px;}#vector{display:flex;margin:0 0 0 auto;justify-content:flex-end;visibility:hidden;}</style></head><body><table id="container" width="100%" border="0" cellspacing="0" cellpadding="0"><td></td><tr><td align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="width:216px;margin:0 120px;"><tr><td align="center"><h1 style="color:#726A5F;font-size:24px;font-weight:700;font-family:'Averia Serif Libre',sans-serif;">${title}</h1></td></tr><tr><td align="center"><p>${text1}</p></td></tr><tr><td align="center"><img src="https://objects-us-east-1.dream.io/augurdigital/dilvant/vector-flower.png" alt="rings" width="159px" height="65px" /></td></tr><tr><td align="center"><p>${text2}</p></td></tr><tr><td align="center"></td></tr></table></td></tr><td></td></table></body></html>`;

// }
